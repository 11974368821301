import React from "react";

import "./App.css";
import { useNavigate } from "react-router-dom";

function App() {
  const navigate = useNavigate();
  return (
    <div className="bg">
      <div className="navButton" onClick={() => navigate(-1)}>
        GO BACK TO APPLICATION
      </div>
    </div>
  );
}

export default App;
